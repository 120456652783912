/* eslint-disable no-param-reassign */
/* eslint-disable no-self-assign */
/* eslint-disable max-len */
export default {
  namespaced: true,
  state: {
    classobj: {
      frontheightline: 1.7,
      fontfamily: 'none',
      fontsize: '14px',
      resummargin: '20px',
      resummarginline: '40px',
      language: '简体中文',
      color: '#4e7282',
    },
    switchobj: {
      switchedu: true,
      switchwork: true,
      switchprogram: false,
      switchinternship: false,
      switchschool: false,
      switchspecial: true,
      switchcertificate: false,
      switchselfeva: true,
      switchhobby: false,
      switchother: false,
    },
    baseinfo: {
      name: '小A',
      age: '1995-11',
      telephone: '12345678901',
      email: '12345678901@qq.com',
      workage: '七年工作经验',
      gender: '男',
      marry: '未婚',
      height: '171',
      weight: '80',
      nation: '汉族',
      native: '湖北',
      politiccountenance: '群众',
      marryvis: '',
      nownative: '上海',
      objective: 'xx工程师',
      city: 'xx城市',
      // 基本信息checkde后是否显示
      namecheckedvis: true,
      agecheckedvis: true,
      phonecheckedvis: true,
      mailcheckedvis: true,
      workagecheckedvis: true,
      photocheckedvis: true,
      gendercheckedvis: true,
      objectivecheckedvis: true,
      heightcheckedvis: false,
      weightcheckedvis: false,
      nationcheckedvis: false,
      nativecheckedvis: false,
      politicscheckedvis: false,
      marrycheckedvis: false,
      nownativecheckedvis: false,
      citycheckedvis: true,
      imageUrl: 'https://www.aiwnjl.com/img/profile.jpg',
    },
    educationinfo: {
      eduparas: [{
        schoolname: 'xx大学',
        field: '计算机',
        edu: '本科',
        schoolyardbeg: '2013-09',
        schoolyardend: '2017-06',
        schoolexp: '<p><strong>专业成绩：</strong>GPA 3.9/4.5<span style=\"background-color: rgb(255, 255, 255); color: rgb(32, 33, 36);\">（专业前5%）</span></p><p><strong>主修课程：</strong>多媒体技术、Oracle数据库、VB程序设计、VC++程序设计、动态网页设计、网络工程、软件工程、数据库原理及应用、EDA技术、单片机原理与接口技术</p>',
      }, {
        schoolname: 'xx大学',
        field: '机械工程',
        edu: '本科',
        schoolyardbeg: '2010-09',
        schoolyardend: '2014-06',
        schoolexp: '',
      }, {
        schoolname: 'xx大学',
        field: '机械工程',
        edu: '本科',
        schoolyardbeg: '2010-09',
        schoolyardend: '2014-06',
        schoolexp: '',
      }, {
        schoolname: 'xx大学',
        field: '机械工程',
        edu: '本科',
        schoolyardbeg: '2010-09',
        schoolyardend: '2014-06',
        schoolexp: '',
      }, {
        schoolname: 'xx大学',
        field: '机械工程',
        edu: '本科',
        schoolyardbeg: '2010-09',
        schoolyardend: '2014-06',
        schoolexp: '',
      }],
      ParamsNum: 1,
    },
    workexpinfo: {
      workparas: [{
        copname: '万能简历',
        jobname: '工程师',
        workbeg: '2014-07',
        workend: '2018-09',
        workexp: '<ul><li><strong>业务需求分析与开发：</strong>深入理解业务需求，与开发团队共同制定开发计划。在最近的项目中，准确把握了业务需求，并在4周内完成了10个功能点的开发，满足了客户的期望。</li><li><strong>系统性能优化：</strong>针对系统性能瓶颈进行定位和优化，通过优化数据库查询语句和增加索引，使得系统响应速度提升了50%，查询效率提高了80%。</li><li><strong>自动化测试与部署：</strong>编写自动化测试用例，确保软件质量。通过自动化部署工具，实现了10次无故障自动化部署，提高了部署效率和降低了人为错误。</li><li><strong>技术支持与培训：</strong>为客户提供及时有效的技术支持，解决了20个技术难题，获得了客户的好评。同时，参与内部培训和技术分享活动5次，提升了团队整体技术水平。</li></ul>',
      }, {
        copname: '万能简历',
        jobname: '工程师',
        workbeg: '2018-09',
        workend: '至今',
        workexp: '<ul><li><strong>产品前端构建：</strong>作为主要前端工程师，负责构建公司核心产品的前端界面，通过引入React框架和Redux进行状态管理，使得页面首次加载时间缩短了30%，用户交互响应时间降低了20%。</li><li><strong>跨平台应用开发：</strong>参与开发了一套基于React Native的跨平台移动应用，实现了iOS和Android双平台的功能同步，减少了40%的开发成本，同时保证应用在不同设备上的稳定运行，用户留存率提高15%。</li><li><strong>性能调优：</strong>针对Web应用在高并发场景下的性能问题，通过引入懒加载、代码分割等技术手段，有效降低了服务器负载35%，提升了应用的响应速度和稳定性。</li></ul>',
      }, {
        copname: '万能简历',
        jobname: '行政',
        workbeg: '2014-07',
        workend: '2018-09',
        workexp: '',
      }, {
        copname: '万能简历',
        jobname: '行政',
        workbeg: '2014-07',
        workend: '2018-09',
        workexp: '',
      }, {
        copname: '万能简历',
        jobname: '行政',
        workbeg: '2014-07',
        workend: '2018-09',
        workexp: '',
      }],
      ParamsNum: 2,
    },
    programsinfo: {
      progparas: [{
        progname: 'xx项目',
        progrole: '项目负责人',
        progbeg: '2014-07',
        progend: '2019-08',
        progexp: '<ul><li style="text-align: left;"><strong>项目名称</strong><span style="color: black;">：xxx监控诊断系统</span></li><li style="text-align: left;"><strong>项目背景</strong><span style="color: black;">：xxx需要一款实时监控系统，该系统要满足：1、能实时监控xx状态，当xx状态发生变化后，即故障初期能向终端推送实时告警；2、能通过前端对所有参数进行配置，同时对xx状态进行分析，对即将发生的故障情况进行预测。</span></li><li style="text-align: left;"><strong>项目负责内容</strong><span style="color: black;">：1、作为项目负责人，负责沟通客户需求，项目进展推进以及版本控制和代码合并；2、实现前后端的的数据交互； 3、建立实验平台进行软硬件的联调和功能测试。</span></li></ul>',
      }, {
        progname: 'xx项目',
        progrole: '项目负责人',
        progbeg: '2014-07',
        progend: '2019-08',
        progexp: '',
      }, {
        progname: 'xx项目',
        progrole: '项目负责人',
        progbeg: '2014-07',
        progend: '2019-08',
        progexp: '',
      }, {
        progname: 'xx项目',
        progrole: '项目负责人',
        progbeg: '2014-07',
        progend: '2019-08',
        progexp: '',
      }, {
        progname: 'xx项目',
        progrole: '项目负责人',
        progbeg: '2014-07',
        progend: '2019-08',
        progexp: '',
      }, {
        progname: 'xx项目',
        progrole: '项目负责人',
        progbeg: '2014-07',
        progend: '2019-08',
        progexp: '',
      }],
      ParamsNum: 1,
    },
    internshipinfo: {
      progparas: [{
        internshipcopname: 'xx公司',
        internshipjobname: '现场协调员',
        internshipbeg: '2013-07',
        internshipend: '2013-08',
        internshipexp: '',
      }, {
        internshipcopname: 'xx公司',
        internshipjobname: '现场协调员',
        internshipbeg: '2013-07',
        internshipend: '2013-08',
        internshipexp: '',
      }, {
        internshipcopname: 'xx公司',
        internshipjobname: '现场协调员',
        internshipbeg: '2013-07',
        internshipend: '2013-08',
        internshipexp: '',
      }, {
        internshipcopname: 'xx公司',
        internshipjobname: '现场协调员',
        internshipbeg: '2013-07',
        internshipend: '2013-08',
        internshipexp: '',
      }, {
        internshipcopname: 'xx公司',
        internshipjobname: '现场协调员',
        internshipbeg: '2013-07',
        internshipend: '2013-08',
        internshipexp: '',
      }, {
        internshipcopname: 'xx公司',
        internshipjobname: '现场协调员',
        internshipbeg: '2013-07',
        internshipend: '2013-08',
        internshipexp: '',
      }],
      ParamsNum: 1,
    },
    schoolexpinfo: {
      progparas: [{
        schoolexpname: 'xx数据建模大赛',
        schoolexprolename: '负责人',
        schoolexpbeg: '2014-07',
        schoolexpend: '2014-12',
        schoolexp: '',
      }, {
        schoolexpname: 'xx数据建模大赛',
        schoolexprolename: '负责人',
        schoolexpbeg: '2014-07',
        schoolexpend: '2014-12',
        schoolexp: '',
      }, {
        schoolexpname: 'xx数据建模大赛',
        schoolexprolename: '负责人',
        schoolexpbeg: '2014-07',
        schoolexpend: '2014-12',
        schoolexp: '',
      }, {
        schoolexpname: 'xx数据建模大赛',
        schoolexprolename: '负责人',
        schoolexpbeg: '2014-07',
        schoolexpend: '2014-12',
        schoolexp: '',
      }, {
        schoolexpname: 'xx数据建模大赛',
        schoolexprolename: '负责人',
        schoolexpbeg: '2014-07',
        schoolexpend: '2014-12',
        schoolexp: '',
      }, {
        schoolexpname: 'xx数据建模大赛',
        schoolexprolename: '负责人',
        schoolexpbeg: '2014-07',
        schoolexpend: '2014-12',
        schoolexp: '',
      }],
      ParamsNum: 1,
    },
    specialityinfo: {
      progparas: [{
        specialityexp: '<ul><li><strong>编程语言：</strong>精通Java、Python，熟悉C++、JavaScript，具备扎实的编程基础和良好的编程习惯。</li><li><strong>框架与库：</strong>熟悉Spring Boot、Django等Web开发框架，熟练使用MySQL、MongoDB等数据库技术。</li><li><strong>软件开发工具：</strong>熟练使用IntelliJ IDEA、PyCharm等IDE，以及Docker、Kubernetes等容器化技术。</li><li><strong>算法与数据结构：</strong>熟练掌握常见的数据结构和算法，具备良好的逻辑思维和问题解决能力。</li></ul>',
      }],
    },
    certificateinfo: {
      progparas: [{
        certificateexp: '<ul><li style="text-align: left;"><strong>优秀大学生（3次）</strong></li><li style="text-align: left;"><strong>CET-4、CET-6;</strong></li><li style="text-align: left;"><strong>全国大学生数学建模大赛二等奖</strong></li></ul>',
      }],
    },
    selfevaluationinfo: {
      progparas: [{
        selfevaluationexp: '<p><strong>技术精湛：</strong>熟练掌握Java、Python、C++等编程语言，具备扎实数据结构与算法基础，能高效解决问题。</p><p><strong>团队协作：</strong>拥有丰富的团队合作经验，擅长与不同背景的团队成员沟通协作，推动项目按时完成。</p><p><strong>持续学习：</strong>热爱学习新技术，关注行业动态，定期参加技术培训和研讨会，保持技术更新与提升。</p>',
      }],
    },
    hobbyinfo: {
      progparas: [{
        hobbyexp: '<ul><li style="text-align: left;">看书、旅游、跑步。</li></ul>',
      }],
    },
    otherinfo: {
      progparas: [{
        otherexp: '<ul><li style="text-align: left;">祝您成功。</li></ul>',
      }],
    },
  },
  mutations: {
    // baseinfo
    // imageurl
    changeimageurlvuex(state, imageUrl) {
      state.baseinfo.imageUrl = imageUrl;
    },
    changenamevuex(state, name) {
      state.baseinfo.name = name;
    },
    changenamevisvuex(state, namecheckedvis) {
      state.baseinfo.namecheckedvis = namecheckedvis;
    },
    changetelephonevuex(state, telephone) {
      state.baseinfo.telephone = telephone;
    },
    changetelephonevisvuex(state, phonecheckedvis) {
      state.baseinfo.phonecheckedvis = phonecheckedvis;
    },
    changeemailvuex(state, email) {
      state.baseinfo.email = email;
    },
    changemailvisvuex(state, mailcheckedvis) {
      state.baseinfo.mailcheckedvis = mailcheckedvis;
    },
    changeworkagevuex(state, workage) {
      state.baseinfo.workage = workage;
    },
    changeworkagevisvuex(state, workagecheckedvis) {
      state.baseinfo.workagecheckedvis = workagecheckedvis;
    },
    changegendervuex(state, gender) {
      state.baseinfo.gender = gender;
    },
    changegendervisvuex(state, gendercheckedvis) {
      state.baseinfo.gendercheckedvis = gendercheckedvis;
    },
    changenativevuex(state, native) {
      state.baseinfo.native = native;
    },
    changenativevisvuex(state, nativecheckedvis) {
      state.baseinfo.nativecheckedvis = nativecheckedvis;
    },
    changenationvuex(state, nation) {
      state.baseinfo.nation = nation;
    },
    changenationvisvuex(state, nationcheckedvis) {
      state.baseinfo.nationcheckedvis = nationcheckedvis;
    },
    changeweightvuex(state, weight) {
      state.baseinfo.weight = weight;
    },
    changeweightvisvuex(state, weightcheckedvis) {
      state.baseinfo.weightcheckedvis = weightcheckedvis;
    },
    changeheightvuex(state, height) {
      state.baseinfo.height = height;
    },
    changeheightvisvuex(state, heightcheckedvis) {
      state.baseinfo.heightcheckedvis = heightcheckedvis;
    },
    changeagevuex(state, age) {
      state.baseinfo.age = age;
    },
    changeagevisvuex(state, agecheckedvis) {
      state.baseinfo.agecheckedvis = agecheckedvis;
    },
    changemarryvuex(state, marry) {
      state.baseinfo.marry = marry;
    },
    changemarryvisvuex(state, marrycheckedvis) {
      state.baseinfo.marrycheckedvis = marrycheckedvis;
    },
    changepoliticcountenancevuex(state, politiccountenance) {
      state.baseinfo.politiccountenance = politiccountenance;
    },
    changepolitocvisvuex(state, politicscheckedvis) {
      state.baseinfo.politicscheckedvis = politicscheckedvis;
    },
    changenownativevuex(state, nownative) {
      state.baseinfo.nownative = nownative;
    },
    changenownativevisvuex(state, nownativecheckedvis) {
      state.baseinfo.nownativecheckedvis = nownativecheckedvis;
    },
    changecityvuex(state, city) {
      state.baseinfo.city = city;
    },
    changecityvisvuex(state, citycheckedvis) {
      state.baseinfo.citycheckedvis = citycheckedvis;
    },
    changeobjectivevuex(state, objective) {
      state.baseinfo.objective = objective;
    },
    changeobjectivevisvuex(state, objectivecheckedvis) {
      state.baseinfo.objectivecheckedvis = objectivecheckedvis;
    },
    changephotovisvuex(state, photocheckedvis) {
      state.baseinfo.photocheckedvis = photocheckedvis;
    },
    // eduinfo
    changeschoolnamevuex(state, schoolnamelod) {
      state.educationinfo.eduparas[schoolnamelod.no].schoolname = schoolnamelod.schoolname;
    },
    changefieldvuex(state, fieldlod) {
      state.educationinfo.eduparas[fieldlod.no].field = fieldlod.field;
    },
    changebegvuex(state, schoolyardbeglod) {
      state.educationinfo.eduparas[schoolyardbeglod.no]
        .schoolyardbeg = schoolyardbeglod.schoolyardbeg;
    },
    changeendvuex(state, schoolyardendlod) {
      state.educationinfo.eduparas[schoolyardendlod.no]
        .schoolyardend = schoolyardendlod.schoolyardend;
    },
    changeeduvuex(state, edulod) {
      state.educationinfo.eduparas[edulod.no].edu = edulod.edu;
    },
    changeContenteduvuex(state, schoolexplod) {
      state.educationinfo.eduparas[schoolexplod.no].schoolexp = schoolexplod.htmval;
    },
    changeeduaddparanumvuex(state, eduparanum) {
      state.educationinfo.ParamsNum = eduparanum;
    },
    // eduinfo.delete
    changeeduparanumvuex(state, edutwopara) {
      state.educationinfo.ParamsNum = edutwopara.ParamsNum;
      const opts = state.educationinfo.eduparas;
      opts.splice(edutwopara.Num, 1);
      opts.push({
        schoolname: 'xx大学',
        field: '机械工程',
        edu: '本科',
        schoolyardbeg: '2010-09',
        schoolyardend: '2014-06',
        schoolexp: '',
      });
    },
    // eduinfo.movedown
    handleMoveDownParamseduvuex(state, num) {
      const opts = state.educationinfo.eduparas;
      console.log(opts);
      // opts.splice(num + 1, 0, opts.splice(num, 1)[0]);
      // eslint-disable-next-line prefer-destructuring
      opts[num + 1] = opts.splice(num, 1, opts[num + 1])[0];
      state.educationinfo.eduparas = opts;
      console.log(opts);
    },
    // eduinfo.moveup
    handleMoveUpParamseduvuex(state, num) {
      const opts = state.educationinfo.eduparas;
      console.log(opts);
      // eslint-disable-next-line prefer-destructuring
      opts[num - 1] = opts.splice(num, 1, opts[num - 1])[0];
      // opts.splice(num - 1, 0, opts.splice(num, 1)[0]);
      state.educationinfo.eduparas = opts;
      console.log(opts);
    },
    // workinfo
    changecopnamevuex(state, copnamelod) {
      state.workexpinfo.workparas[copnamelod.no].copname = copnamelod.copname;
    },
    changejobnamevuex(state, jobnamelod) {
      state.workexpinfo.workparas[jobnamelod.no].jobname = jobnamelod.jobname;
    },
    changeworkbegvuex(state, workbeglod) {
      state.workexpinfo.workparas[workbeglod.no].workbeg = workbeglod.workbeg;
    },
    changeworkendvuex(state, workendlod) {
      state.workexpinfo.workparas[workendlod.no].workend = workendlod.workend;
    },
    changeContentworkvuex(state, workexplod) {
      state.workexpinfo.workparas[workexplod.no].workexp = workexplod.htmval;
    },
    changeworkaddparanumvuex(state, workparanum) {
      state.workexpinfo.ParamsNum = workparanum;
    },
    // workinfo.delete
    changeworkparanumvuex(state, worktwopara) {
      state.workexpinfo.ParamsNum = worktwopara.ParamsNum;
      const opts = state.workexpinfo.workparas;
      opts.splice(worktwopara.Num, 1);
      opts.push({
        // worktimechecked: false,
        copname: '万能简历',
        jobname: '行政',
        workbeg: '2014-07',
        workend: '2018-09',
        workexp: '',
        // workenddis: false,
      });
    },
    // workinfo.movedown
    handleMoveDownParamsworkvuex(state, num) {
      const opts = state.workexpinfo.workparas;
      opts.splice(num + 1, 0, opts.splice(num, 1)[0]);
      state.workexpinfo.workparas = opts;
    },
    // workinfo.moveup
    handleMoveUpParamsworkvuex(state, num) {
      const opts = state.workexpinfo.workparas;
      opts.splice(num - 1, 0, opts.splice(num, 1)[0]);
      state.workexpinfo.workparas = opts;
    },
    // programsinfo
    changeprognamevuex(state, prognamelod) {
      state.programsinfo.progparas[prognamelod.no].progname = prognamelod.progname;
    },
    changeprogrolevuex(state, progrolelod) {
      state.programsinfo.progparas[progrolelod.no].progrole = progrolelod.progrole;
    },
    changeprogbegvuex(state, progbeglod) {
      state.programsinfo.progparas[progbeglod.no].progbeg = progbeglod.progbeg;
    },
    changeprogendvuex(state, progendlod) {
      state.programsinfo.progparas[progendlod.no].progend = progendlod.progend;
    },
    changeContentprogvuex(state, progexplod) {
      state.programsinfo.progparas[progexplod.no].progexp = progexplod.htmval;
    },
    changeprogaddparanumvuex(state, progparanum) {
      state.programsinfo.ParamsNum = progparanum;
    },
    // programinfo.delete
    changeprogparanumvuex(state, progtwopara) {
      state.programsinfo.ParamsNum = progtwopara.ParamsNum;
      const opts = state.programsinfo.progparas;
      opts.splice(progtwopara.Num, 1);
      opts.push({
        progname: 'xx项目',
        progrole: '项目负责人',
        progbeg: '2014-07',
        progend: '2019-08',
        progexp: '',
      });
    },
    // programinfo.movedown
    handleMoveDownParamsprogvuex(state, num) {
      const opts = state.programsinfo.progparas;
      opts.splice(num + 1, 0, opts.splice(num, 1)[0]);
      state.programsinfo.progparas = opts;
    },
    // programinfo.moveup
    handleMoveUpParamsprogvuex(state, num) {
      const opts = state.programsinfo.progparas;
      opts.splice(num - 1, 0, opts.splice(num, 1)[0]);
      state.programsinfo.progparas = opts;
    },
    // internshipinfo
    changeinternshipcopnamevuex(state, internshipcopnamelod) {
      state.internshipinfo.progparas[internshipcopnamelod.no]
        .internshipcopname = internshipcopnamelod.internshipcopname;
    },
    changeinternshipjobnamevuex(state, internshipjobnamelod) {
      state.internshipinfo.progparas[internshipjobnamelod.no]
        .internshipjobname = internshipjobnamelod.internshipjobname;
    },
    changeinternshipbegvuex(state, internshipbeglod) {
      state.internshipinfo.progparas[internshipbeglod.no]
        .internshipbeg = internshipbeglod.internshipbeg;
    },
    changeinternshipendvuex(state, internshipendlod) {
      state.internshipinfo.progparas[internshipendlod.no]
        .internshipend = internshipendlod.internshipend;
    },
    changeContentinternvuex(state, internshipexplod) {
      state.internshipinfo.progparas[internshipexplod.no]
        .internshipexp = internshipexplod.htmval;
    },
    changeinternshipaddparanumvuex(state, intershipparanum) {
      state.internshipinfo.ParamsNum = intershipparanum;
      console.log(state.internshipinfo.ParamsNum);
    },
    // internshipinfo.delete
    changeinternshipparanumvuex(state, internshiptwopara) {
      state.internshipinfo.ParamsNum = internshiptwopara.ParamsNum;
      const opts = state.internshipinfo.progparas;
      opts.splice(internshiptwopara.Num, 1);
      opts.push({
        internshipcopname: 'xx公司',
        internshipjobname: '现场协调员',
        internshipbeg: '2013-07',
        internshipend: '2013-08',
        internshipexp: '',
      });
    },
    // internshipinfo.movedown
    handleMoveDownParamsinternshipvuex(state, num) {
      const opts = state.internshipinfo.progparas;
      opts.splice(num + 1, 0, opts.splice(num, 1)[0]);
      state.internshipinfo.progparas = opts;
    },
    // internshipinfo.moveup
    handleMoveUpParamsinternshipvuex(state, num) {
      const opts = state.internshipinfo.progparas;
      opts.splice(num - 1, 0, opts.splice(num, 1)[0]);
      state.internshipinfo.progparas = opts;
    },
    // schoolexpinfo
    changeschoolexpnamevuex(state, schoolexpnamelod) {
      state.schoolexpinfo.progparas[schoolexpnamelod.no]
        .schoolexpname = schoolexpnamelod.schoolexpname;
    },
    changeschoolexprolenamevuex(state, schoolexprolenamelod) {
      state.schoolexpinfo.progparas[schoolexprolenamelod.no]
        .schoolexprolename = schoolexprolenamelod.schoolexprolename;
    },
    changeschoolexpbegvuex(state, schoolexpbeglod) {
      state.schoolexpinfo.progparas[schoolexpbeglod.no]
        .schoolexpbeg = schoolexpbeglod.schoolexpbeg;
    },
    changeschoolexpendvuex(state, schoolexpendlod) {
      state.schoolexpinfo.progparas[schoolexpendlod.no]
        .schoolexpend = schoolexpendlod.schoolexpend;
    },
    changeContentschoolvuex(state, schoolexplod) {
      state.schoolexpinfo.progparas[schoolexplod.no]
        .schoolexp = schoolexplod.htmval;
    },
    changeschooladdparanumvuex(state, progparanum) {
      state.schoolexpinfo.ParamsNum = progparanum;
    },
    // schoolinfo.delete
    changeschoolparanumvuex(state, schooltwopara) {
      state.schoolexpinfo.ParamsNum = schooltwopara.ParamsNum;
      const opts = state.schoolexpinfo.progparas;
      opts.splice(schooltwopara.Num, 1);
      opts.push({
        schoolexpname: 'xx数据建模大赛',
        schoolexprolename: '负责人',
        schoolexpbeg: '2014-07',
        schoolexpend: '2014-12',
        schoolexp: '',
      });
    },
    // schoolinfo.movedown
    handleMoveDownParamsschoolvuex(state, num) {
      const opts = state.schoolexpinfo.progparas;
      opts.splice(num + 1, 0, opts.splice(num, 1)[0]);
      state.schoolexpinfo.progparas = opts;
    },
    // schoolinfo.moveup
    handleMoveUpParamsschoolvuex(state, num) {
      const opts = state.schoolexpinfo.progparas;
      opts.splice(num - 1, 0, opts.splice(num, 1)[0]);
      state.schoolexpinfo.progparas = opts;
    },
    // 技能
    changeContentspecialityvuex(state, specialityexplod) {
      state.specialityinfo.progparas[specialityexplod.no]
        .specialityexp = specialityexplod.htmval;
    },
    // 荣誉证书
    changeContentcertificatevuex(state, certificateexplod) {
      state.certificateinfo.progparas[certificateexplod.no]
        .certificateexp = certificateexplod.htmval;
    },
    // 自我评价
    changeContentselfevaluationvuex(state, selfevaluationexplod) {
      state.selfevaluationinfo.progparas[selfevaluationexplod.no]
        .selfevaluationexp = selfevaluationexplod.htmval;
    },
    // 兴趣爱好
    changeContenthobbyvuex(state, hobbyexplod) {
      state.hobbyinfo.progparas[hobbyexplod.no]
        .hobbyexp = hobbyexplod.htmval;
    },
    // other
    changeContentothervuex(state, otherexplod) {
      state.otherinfo.progparas[otherexplod.no].otherexp = otherexplod.htmval;
    },
    // classheightline
    changeheightlinevuex(state, classheightline) {
      state.classobj.frontheightline = classheightline;
    },
    // classfontfamily
    changefontfamliyvuex(state, classfontfamily) {
      state.classobj.fontfamily = classfontfamily;
    },
    // classfontsize
    changefontsizevuex(state, classfontsize) {
      state.classobj.fontsize = classfontsize;
    },
    // classmargin
    changemarginvuex(state, classmargin) {
      state.classobj.resummargin = classmargin;
      if (classmargin === '5px') {
        state.classobj.resummarginline = '25px';
      } else if (classmargin === '9px') {
        state.classobj.resummarginline = '29px';
      } else if (classmargin === '13px') {
        state.classobj.resummarginline = '33px';
      } else if (classmargin === '17px') {
        state.classobj.resummarginline = '37px';
      } else if (classmargin === '21px') {
        state.classobj.resummarginline = '41px';
      } else if (classmargin === '25px') {
        state.classobj.resummarginline = '45px';
      } else if (classmargin === '29px') {
        state.classobj.resummarginline = '49px';
      } else if (classmargin === '33px') {
        state.classobj.resummarginline = '53px';
      } else if (classmargin === '37px') {
        state.classobj.resummarginline = '57px';
      } else if (classmargin === '41px') {
        state.classobj.resummarginline = '61px';
      } else if (classmargin === '45px') {
        state.classobj.resummarginline = '65px';
      } else {
        state.classobj.resummarginline = '45px';
      }
    },
    // classlanguage
    changelanguagevuex(state, classlanguage) {
      state.classobj.language = classlanguage;
    },
    // classcolor
    changecolorvuex(state, classcolor) {
      state.classobj.color = classcolor;
    },
    // switchbaseinfo
    changeswitcheduvuex(state, switchedu) {
      state.switchobj.switchedu = switchedu;
    },
    // changeswitchworkvuex
    changeswitchworkvuex(state, switchwork) {
      state.switchobj.switchwork = switchwork;
    },
    // switchprogram
    changeswitchprogramvuex(state, switchprogram) {
      state.switchobj.switchprogram = switchprogram;
    },
    // switchinternship
    changeswitchinternshipvuex(state, switchinternship) {
      state.switchobj.switchinternship = switchinternship;
    },
    // switchschool
    changeswitchschoolvuex(state, switchschool) {
      state.switchobj.switchschool = switchschool;
    },
    // switchspecial
    changeswitchspecialvuex(state, switchspecial) {
      state.switchobj.switchspecial = switchspecial;
    },
    // switchcertificate
    changeswitchcertificatevuex(state, switchcertificate) {
      state.switchobj.switchcertificate = switchcertificate;
    },
    // switchselfeva
    changeswitchselfevavuex(state, switchselfeva) {
      state.switchobj.switchselfeva = switchselfeva;
    },
    // switchselfeva
    changeswitchhobbyvuex(state, switchhobby) {
      state.switchobj.switchhobby = switchhobby;
    },
    // switchother
    changeswitchothervuex(state, switchother) {
      state.switchobj.switchother = switchother;
    },
  },
  actions: {},
};
