/* eslint-disable no-var */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import VueLazyload from 'vue-lazyload';
import VueQuillEditor from 'vue-quill-editor'; // 引入富文本编译器
import htmlToPdf from './utils/htmlToPdf';
import App from './App.vue';
import router from './router';
import store from './store';
import getScore from './utils/getScore';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

Vue.use(VueQuillEditor);

Vue.prototype.$http = axios;

Vue.config.productionTip = true;
Vue.use(ElementUI);
Vue.use(htmlToPdf);
Vue.use(getScore);

const loadimage = require('@/assets/loading.gif');
const errorimage = require('@/assets/fail.jpeg');

Vue.use(VueLazyload, {
  preLoad: 1.3, // 预加载的高度比例
  error: errorimage, // 加载失败时显示的图片
  loading: loadimage, // 加载中显示的图片
  attempt: 3, // 尝试加载次数
  listenEvents: ['scroll'], // 监听的事件类型
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
// eslint-disable-next-line vars-on-top, no-use-before-define
var _hmt = _hmt || [];
window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到

// 统计代码获取
// eslint-disable-next-line func-names
(function () {
  const hm = document.createElement('script');
  hm.src = 'https://hm.baidu.com/hm.js?a318be0408ed04c2e24d8c54ba1ff47f';
  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(hm, s);
}());
